// extracted by mini-css-extract-plugin
export var ArtistDescription = "MathiasBensimon-module--ArtistDescription--0-6ON";
export var ArtistInfos = "MathiasBensimon-module--ArtistInfos--qkYEF";
export var ButtonWrapper = "MathiasBensimon-module--ButtonWrapper --QKD8G";
export var CardWrapper = "MathiasBensimon-module--CardWrapper--NUXXr";
export var CarrouselWrapper2 = "MathiasBensimon-module--CarrouselWrapper2--rUd+W";
export var Citations = "MathiasBensimon-module--Citations--LuFRk";
export var DescriptionWrapper = "MathiasBensimon-module--DescriptionWrapper--quB9c";
export var ImageWrapper = "MathiasBensimon-module--ImageWrapper--2cdJk";
export var LinkWrapper = "MathiasBensimon-module--LinkWrapper--c0wF2";
export var MobileProtrait = "MathiasBensimon-module--MobileProtrait--ntG62";
export var More = "MathiasBensimon-module--More--WAA3F";
export var MoreButton = "MathiasBensimon-module--MoreButton--9z6Ei";
export var NameWrapper = "MathiasBensimon-module--NameWrapper--C6A4G";
export var PdpWrapper = "MathiasBensimon-module--PdpWrapper--DmraS";
export var PhotosWrapper = "MathiasBensimon-module--PhotosWrapper--ztNgw";
export var ProfilWrapper = "MathiasBensimon-module--ProfilWrapper--3Umz8";
export var TitleWrapper = "MathiasBensimon-module--TitleWrapper--oA87y";
export var Wrapper = "MathiasBensimon-module--Wrapper--ad+BQ";