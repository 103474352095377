import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./MathiasBensimon.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import AonePhoto5 from "../../../../res/Photos site/A-one/A-ONE-portrait.jpg";

import MathiasPdp from "./../../../../res/bensimon/portrait.jpg";
import Button from "../../../../components/Button";
import PastPresent1 from "../../../../res/bensimon/oeuvre-2.png"
import PastPresent2 from "../../../../res/bensimon/oeuvre-1.png"
import PastPresent3 from "../../../../res/bensimon/oeuvre-3.png"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "mathias-bensimon",
  name: "MATHIAS BENSIMON",
  description: 
  "Graduated from the Beaux-Arts de Paris in 2022, Mathias Bensimon is a French artist, visual artist, performer and dancer. During his training, he joined the studio of Ann Veronica Janssens, a famous Belgian artist who has questioned the effects of transparency and light and with whom he was able to study the relationship between experimentation and perception. Mathias will exhibit at the Musée d'Orsay next March as part of the exhibition \"Pastels, from Millet to Redon\" from March 14 to July 2. In October 2022, he will participate in the exhibition organized by Lawrence Van Hagen \"California Light & Space & Beyond\" at Paulin in Paris. Since 2019, he has also collaborated with the Musée du Grand Palais in Paris as part of EuroFabrique, the Museu Arqueologico do Carmo, the Museum of Fine arts, the Ho Chi Minh City, the Temple of Hesadera, the Tokyo Musashino museum as well as the Château de la colle noire where he participates in the Dior prize.",
  citations: [
    "STATEMENT",
    "Dans le champ de ma pratique, la lumière constitue le coeur de mon travail. La lumière en tant que médium et substance, mais aussi la lumière en tant qu’intelligence révélatrice. Tel un témoignage des différentes manifestations du réel, la lumière peut nous apparaître sous une multitude de formes et de réflexion. Elle pose alors la question du visible et de l’invisible. Comment rendre visible l’invisible ? Comment toucher la source immatérielle en passant par la matière ? Mathias Bensimon"
  ],
  pdp: MathiasPdp,
  alt_pdp: "Photo de profil de MATHIAS BENSIMON.",
  photos: [
    { src: PastPresent1, name: "MATHIAS BENSIMON" },
    { src: PastPresent2, name: "MATHIAS BENSIMON" },
    { src: PastPresent3, name: "MATHIAS BENSIMON" },
  ],
};

const MathiasBensimon = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
   {/* <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet> */}
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>MATHIAS BENSIMON</h1>
          <h3 className={NameWrapper}>Born in 1996</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
      </div>
      </div>

      <div className={PhotosWrapper}>
        <Title title={"Art"} />
        <div className={CarrouselWrapper2}>
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  style={{objectFit: "contain"}}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default MathiasBensimon;